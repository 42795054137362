import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import BannerImage from '../components/banner-image'
import BasicContent from '../components/basic-content'
import SocialBar from '../components/social-bar'
import BrandBanner from '../components/brand-banner'

const social = {
  title: 'Add me to the Dad list',
  className: 'full'
}

class BasicPage extends Component {
  render() {
    const page = this.props.data.wordpressPage

    //{ page.featured_media && <BannerImage {...page} /> }
    return (
      <>
        <Helmet>
          <body className='basic-page' />
          <title>{ he.decode(page.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={ page.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="og:title" content={ he.decode(page.yoast_meta.yoast_wpseo_title) } />
          <meta property="og:description" content={ page.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="twitter:title" content={ he.decode(page.yoast_meta.yoast_wpseo_title) } />
          <meta property="twitter:description" content={ page.yoast_meta.yoast_wpseo_metadesc } />
        </Helmet>
        <BasicContent {...page} />
        <SocialBar {...social} />
        <BrandBanner />
      </>
    )
  }
}

export default BasicPage

export const privacyQuery = graphql`
  query pageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
