import React, { Component } from 'react'

class BannerImage extends Component {
  render() {
    return (
      <div className="banner-image">
        <img src={this.props.featured_media.url} alt={this.props.title} />
      </div>
    )
  }
}

export default BannerImage
